<template>
  <div>
    <search-field title="条件筛选">
      <el-form :inline="true" size="small" label-width="100px">
        <el-form-item label="商品名称" >
          <el-input clearable v-model="search.searchFields.goods_name" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="商品编码" >
          <el-input clearable v-model="search.searchFields.goods_number" placeholder="请输入商品编码"></el-input>
        </el-form-item>
      </el-form>
      <div class="search-button-container">
        <el-button type="primary" size="small" @click="getGoodsList(1)">查询</el-button>
        <el-button type="primary" size="small"  @click="clearSearch()">重置</el-button>
      </div>
    </search-field>
    <div class="page-content">
      <div class="button-field">
        <el-button type="primary" size="small" @click="handleAdd()">添加</el-button>
      </div>
      <v-table
        :data="options.data"
        :columns="options.columns"
        :isBackPage="true"
        :totalCount="options.totalCount"
        :defaultcurrentPage="search.searchFields.page"
        @handleCurrentChange="handlePageChange"
        @handleSizeChange="handleSizeChange"
        @buttonClick="handleButtonClick"
      >
      </v-table>
    </div>
    <el-dialog
      title="新增"
      width="25%"
      :visible.sync="shopAddDialog"
      >
        <el-form size="small" label-width="110px">
          <el-form-item
            label="商品编码"
            required
            :error="errorBags.collect('商品编码')[0]"
          >
            <el-input
              v-model="goodsForm.goods_number"
              placeholder="请输入商品编码"
              v-validate="'required'"
              data-vv-name="商品编码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="商品名称"
            required
            :error="errorBags.collect('商品名称')[0]"
          >
            <el-input
              type="tel"
              v-model="goodsForm.goods_name"
              maxlength="36"
              placeholder="请输入商品名称"
              v-validate="'required'"
              data-vv-name="商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="市场价"
            required
            :error="errorBags.collect('市场价')[0]"
          >
            <el-input
              type="tel"
              v-model="goodsForm.market_price"
              maxlength="12"
              placeholder="请输入市场价"
              v-validate="'required'"
              data-vv-name="市场价"
            ></el-input>
          </el-form-item>
          <el-form-item
              label="返现支数"
              required
              :error="errorBags.collect('返现支数')[0]"
          >
            <el-input
                type="tel"
                v-model="goodsForm.return_num"
                maxlength="12"
                placeholder="请输入返现支数"
                v-validate="'required'"
                data-vv-name="返现支数"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="返现金额"
            required
            :error="errorBags.collect('返现金额')[0]"
          >
            <el-input
              type="tel"
              v-model="goodsForm.return_amount"
              maxlength="12"
              placeholder="请输入返现金额"
              v-validate="'required'"
              data-vv-name="返现金额"
            ></el-input>
          </el-form-item>
          <el-form-item
              label="附加返现数量"
              required
              :error="errorBags.collect('附加返现数量')[0]"
          >
            <el-input
                type="tel"
                v-model="goodsForm.return_extend_num"
                maxlength="12"
                placeholder="请输入附加返现数量"
                v-validate="'required'"
                data-vv-name="附加返现数量"
            ></el-input>
          </el-form-item>
          <el-form-item
              label="附加返现金额"
              required
              :error="errorBags.collect('附加返现金额')[0]"
          >
            <el-input
                type="tel"
                v-model="goodsForm.return_extend_amount"
                maxlength="12"
                placeholder="请输入附加返现金额"
                v-validate="'required'"
                data-vv-name="附加返现金额"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" required :error="errorBags.collect('状态')[0]">
            <el-select v-model="goodsForm.status" v-validate="'required'" data-vv-name="状态">
              <el-option
                v-for="item in statusOptions"
                :key="item.code"
                :label="item.value"
                :value="item.code"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="btn-container">
            <el-button @click="closeModal">取消</el-button>
            <el-button type="primary" @click="handleSubmit">确定</el-button>
          </div>
        </el-form>
      </el-dialog>
  </div>
  </template>
<script>

import vTable from '@/components/tools/vtable'
import searchField from '@/components/tools/searchField'
import { BAApi } from '@/api'
import { formatDate } from '@/libs/utils'

export default {
  data() {
    return {
      options: {
        columns: [
          {
            label: '商品名称',
            key: 'goods_name'
          },
          {
            label: '商品编码',
            key: 'goods_number'
          },
          {
            label: '市场价',
            key: 'market_price'
          },
          {
            label: '返现金额',
            key: 'return_amount'
          },
          {
            label: '返现支数',
            key: 'return_num'
          },
          {
            label: '附加返现数量',
            key: 'return_extend_num'
          },
          {
            label: '附加返现金额',
            key: 'return_extend_amount'
          },
          {
            label: '状态',
            key: 'status_msg'
          },
          {
            label: '创建时间',
            type: 'render',
            render: (h, { row }) => {
              return (
                <div>
                  <div class="status-td">
                    {
                      <div>{formatDate(Number(row.create_time) * 1000, 'YYYY-MM-DD HH:mm:ss').dateString}</div>
                    }
                  </div>
                </div>
              )
            }
          },
          {
            label: '更新时间',
            type: 'render',
            render: (h, { row }) => {
              return (
                <div>
                  <div class="status-td">
                    {
                      <div>{formatDate(Number(row.update_time) * 1000, 'YYYY-MM-DD HH:mm:ss').dateString}</div>
                    }
                  </div>
                </div>
              )
            }
          },
          {
            label: '操作',
            type: 'action',
            selectButton: true,
            buttonInfos: [
              {
                name: 'edit',
                label: '编辑',
                color: 'primary'
              },
            ]
          }
        ],
        data: [],
        totalCount: 0,
        loading: false,
      },
      search: {
        searchFields: {
          page: 1,
          count: 20,
          goods_name: '',
          goods_number: '',
        }
      },
      statusOptions: [
        {
          code: 1,
          value: '正常'
        },{
          code: 0,
          value: '下架'
        },
      ],
      shopAddDialog: false,
      goodsForm: {
        goods_name: '',
        goods_number: '',
        market_price: '',
        return_amount: '',
        return_num:'',
        return_extend_num: '',
        return_extend_amount: '',
        status: 1
      },
      editGoodsId: '',
    }
  },

  methods: {
    // 后端分页
    handlePageChange(page) {
      this.search.searchFields.page = page
      this.getGoodsList()
    },
    handleSizeChange(count) {
      this.search.searchFields.count = count
      this.getGoodsList(1)
    },
    async getGoodsList(page) {
      let loading = this.$loading()
      this.search.searchFields.page = page || this.search.searchFields.page
      try {
        let data = (await BAApi.getGoodsList(this.search.searchFields)).data
        console.log(data)
        if (data.code === 0) {
          this.$validator.reset()
          this.options.data = data.goods_list.map(item => {
            item.btnList = ['edit']
            return item
          })
          this.options.totalCount = parseInt(data.total_count) || 0
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    clearSearch() {
      this.search = {
        searchFields: Object.assign({ count: this.search.searchFields.count }, {
          page: 1,
          goods_name: '',
          goods_number: '',
        })
      }
    },
    async handleButtonClick(value) {
      let type = value.button,
        data = value.data;
      this.editGoodsId = data.id;

      if (type === 'edit') {
        this.shopAddDialog = true;
        this.goodsForm.goods_name = data.goods_name;
        this.goodsForm.goods_number = data.goods_number;
        this.goodsForm.status = Number(data.status);
        this.goodsForm.return_amount = data.return_amount;
        this.goodsForm.return_num = data.return_num;
        this.goodsForm.return_extend_num = data.return_extend_num;
        this.goodsForm.return_extend_amount = data.return_extend_amount;
        this.goodsForm.market_price = data.market_price;
      }
    },
    handleAdd() {
      this.resetForm()
      this.$validator.reset()
      this.shopAddDialog = true
    },
    closeModal() {
      this.resetForm()
      this.shopAddDialog = false
    },
    handleSubmit() {
      this.$validator.validate().then(status => {
        if (status) {
          this.addGoods()
        } else {
          this.$message.error('请正确填写数据')
        }
      })
    },
    async addGoods() {
      let loading = this.$loading()
      try {
        let params = {
          ...this.goodsForm,
          goods_id: this.editGoodsId,
        }
        let data = (await BAApi.addOrEditGoods(params)).data
        if (data.code === 0) {
          loading.close()
          this.$validator.reset()
          this.$message.success('添加商品成功')
          this.resetForm()
          this.shopAddDialog = false
          this.getGoodsList()
        } else {
          loading.close()
          this.$error(data)
        }
      } catch (error) {
        this.$validator.reset()
        loading.close()
      }
    },
    resetForm() {
      let obj = {
        goods_number: '',
        goods_name: '',
        market_price: '',
        return_amount: '',
        return_num:'',
        return_extend_num: '',
        return_extend_amount:''
      }
      this.goodsForm = obj
      this.editGoodsId = '';
    }
  },

  mounted() {
    this.getGoodsList()
  },
  components: {
    vTable, searchField
  }
}
</script>

  <style lang="less">
  .status-td {
    display: flex;
    align-items: center;
    justify-content: center;
    .tag {
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 6px;
    }
    .tag2 {
      background-color: #67C23A;
    }
    .tag3 {
      background-color: #F56C6C;
    }
  }
  </style>

